<template>
    <div class="line">
        <el-divider></el-divider>
    </div>
</template>

<script>

export default {
    props: {
    },
}
</script>

<style lang="scss" scoped>
</style>
